import { IContentColors } from '@/types';
import { getFullName } from '@/utils/helpers';
import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, ChevronUp } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import {
  HOVER_NAV_ICON_SIZE,
  SMALL_ICON_SIZE,
} from '../../../styles/iconSizes';
import StageIcon from '../../ui/new-icons/Stage';
import SwitchSessionPromptsModal from './SwitchSessionPromptsModal';
import styles from './styles.module.scss';
import {
  ITrackListItemProps,
  ITrackListProps,
  ITrackSelectionChevronProps,
  Track,
} from './types';
import { X } from '@styled-icons/boxicons-regular';
import Parser from 'html-react-parser';

const TrackSelectionChevron = ({ orientation }: ITrackSelectionChevronProps) =>
  orientation === 'up' ? (
    <ChevronUp className={styles.chevron} size={HOVER_NAV_ICON_SIZE} />
  ) : (
    <ChevronDown className={styles.chevron} size={HOVER_NAV_ICON_SIZE} />
  );

const TrackListItem = React.memo(({ track, onSelect }: ITrackListItemProps) => {
  const speakersToDisplay = useMemo(() => track.speakers?.slice(0, 3) ?? [], [
    track.speakers,
  ]);

  const speakerNamesString = useMemo(
    () =>
      speakersToDisplay
        .map(({ firstName, lastName }) => getFullName(firstName, lastName))
        .join(' · '),
    [speakersToDisplay],
  );

  const plusExtraCount =
    track.speakers.length > speakersToDisplay.length
      ? track.speakers.length - speakersToDisplay.length
      : 0;

  return (
    <div
      aria-hidden
      data-tip
      data-for="go-to-session-anchor"
      className={styles.trackListItemOuter}
      onClick={() => onSelect(track)}
    >
      <div className={styles.trackListItem}>
        <div className={styles.trackListItemInner}>
          <div className={styles.trackDetails}>
            <div className={styles.trackStage}>
              <div className={styles.trackStageIcon}>
                <StageIcon color={IContentColors.WHITE} size={18} />
              </div>
              <div className={styles.trackStageText}>{track.stageName}</div>
            </div>
            <div className={styles.trackTitle}>{track.title}</div>
            <div className={styles.trackSubtitle}>
              {moment(track.startDateTime).format('ddd, Do MMM')}
              <Dot />
              {moment(track.startDateTime).format('hh:mm A')}
              {' - '}
              {moment(track.endDateTime).format('hh:mm A')}
            </div>
            <div className={styles.trackSpeakers}>
              <span className={styles.trackSpeakerNames}>
                {speakerNamesString}
              </span>
              {plusExtraCount > 0 ? (
                <span className={styles.trackSpeakerOthers}>
                  +{plusExtraCount}
                </span>
              ) : null}
            </div>
          </div>
          <ChevronRight
            className={styles.chevronRight}
            size={SMALL_ICON_SIZE}
          />
        </div>
      </div>
    </div>
  );
});

const Dot = () => <div className={styles.dotSeparator}> &#183; </div>;

const TrackList = ({ tracks, onSelect }: ITrackListProps) => (
  <div className={styles.trackListContainer}>
    <div className={styles.trackListHeader}>
      <div className={styles.trackListHeaderTitle}>
        Explore other live sessions
      </div>
      <div className={styles.trackListHeaderSubtitle}>
        {tracks.length > 1
          ? `${tracks.length} other sessions are live`
          : '1 other session is live'}
      </div>
      <button
        className={styles.closePanel}
        type="button"
        onClick={() => onSelect()}
      >
        <X size={24} color="#cdcdcd" />
      </button>
    </div>
    <div className={styles.trackListScrollable}>
      {tracks.map(track => (
        <TrackListItem
          // eslint-disable-next-line react/no-array-index-key
          key={track.id}
          track={track}
          onSelect={onSelect}
        />
      ))}
    </div>
    <ReactTooltip
      id="go-to-session-anchor"
      place="bottom"
      effect="solid"
      multiline
      class={styles.tooltipContainer}
    >
      Go to session
    </ReactTooltip>
  </div>
);

const TrackSelection = ({
  eventId,
  currentSegment,
  segmentStatus,
  otherRunningTracks,
  showJoinBackstageButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [stageIdToRedirect, setStageIdToRedirect] = useState<string | false>(
    false,
  );

  const handleTrackSelected = (track: Track) => {
    setIsOpen(false);
    if (track) {
      setStageIdToRedirect(track.stageId);
    }
  };

  const handleRedirect = useCallback(() => {
    const url = `/l/event/${eventId}/stages/${stageIdToRedirect}`;
    window.location.href = url;
  }, [eventId, stageIdToRedirect]);

  const toggleSelectionDialog = () => {
    if (otherRunningTracks.length) {
      setIsOpen(s => !s);
    }
  };

  return (
    <>
      <div>
        {currentSegment && currentSegment?.title ? (
          <div
            className={classnames([styles.trackSelectionContainer], {
              [styles.trackSelectionContainerClickable]:
                otherRunningTracks.length > 0,
            })}
            data-tip
            data-for="change-track-anchor"
            onClick={toggleSelectionDialog}
            aria-hidden
          >
            <div className={styles.trackAnchorDetails}>
              <div className={styles.segmentStatus}>{segmentStatus}</div>
              <div className={styles.trackAnchorTitle}>
                {currentSegment?.title}
              </div>
            </div>
            {otherRunningTracks.length > 0 ? (
              <TrackSelectionChevron orientation={isOpen ? 'up' : 'down'} />
            ) : null}
            <ReactTooltip
              id="change-track-anchor"
              place="top"
              effect="solid"
              multiline
              class={
                showJoinBackstageButton
                  ? styles.tooltipContainerWithBackstageBtn
                  : styles.tooltipContainer
              }
            >
              {!!currentSegment?.description
                ? Parser(currentSegment?.description)
                : currentSegment?.title}
            </ReactTooltip>
          </div>
        ) : null}
      </div>
      {isOpen &&
        currentSegment &&
        currentSegment?.title &&
        otherRunningTracks.length > 0 && (
          <div className={styles.floatingTrackList}>
            <TrackList
              onSelect={handleTrackSelected}
              tracks={otherRunningTracks}
            />
          </div>
        )}
      <SwitchSessionPromptsModal
        title="Switching sessions?"
        subTitle="Are you sure you want to exit this session and move to a different session?"
        show={stageIdToRedirect}
        setShow={setStageIdToRedirect}
        onSwitch={handleRedirect}
      />
    </>
  );
};

export default TrackSelection;
